import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]
  static classes = [ "hide" ]

  toggle(event) {
    this.itemTarget.classList.toggle(this.hideClass)

    // When it toggles to hidden, remove the focus from the button
    if(this.itemTarget.classList.contains(this.hideClass)) {
      event.target.blur()
    }
  }

  // Use this method to hide the dropdown when clicking outside of it
  close(event) {
    if(this.element === event.target || this.element.contains(event.target)) return

    this.hide()
  }

  disconnect() {
    this.hide()
  }

  // private
  hide() {
    this.itemTarget.classList.add(this.hideClass)
  }
}
